import axios from '@axios';

export function listCalendars() {
    return axios.get('/google-calendar/calendars');
}

export function newCalendar(data) {
    return axios.post('/google-calendar/calendars', data);
}

export function getCalendar(id) {
    return axios.post('google-calendar/calendars/' + id);
}

export function deleteCalendar(id) {
    return axios.delete('/google-calendar/calendars/' + id);
}

export function authorize(code, scope) {
    return axios.get('/google-calendar/authorize?code=' + code + '&scope=' + scope);
}

export function getContacts() {
    return axios.get('/google-calendar/contacts');
}
