<template>
    <v-container fluid class="d-flex justify-center" fill-height>
        <v-snackbar v-model="snackbar.visible" color="error" top rounded="pill" :centered="true"
            ><v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                        <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
            </v-layout></v-snackbar
        >

        <v-progress-circular :size="100" indeterminate color="primary"></v-progress-circular>
    </v-container>
</template>

<script>
    import { authorize } from '@/api/google-calendar';
    import { mdiAlertCircle } from '@mdi/js';
    import getTrad from '../i18n/getTrad';

    export default {
        mixins: [getTrad],
        data() {
            return {
                snackbar: {
                    visible: false,
                    text: '',
                    title: '',
                    icon: mdiAlertCircle,
                },
            };
        },
        beforeMount() {
            // authorize(this.$route.query.code, this.$route.query.scope)
            //     .then(() => {
            //         this.$router.push({ name: 'Calendar' });
            //     })
            //     .catch((error) => {
            //         this.$snackbar({
            //             text: this.getTrad('GoogleSendPermissions.errorText'),
            //             color: 'error',
            //             timeout: 2000,
            //         });
            //     });
        },
    };
</script>
